var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('b-alert',{attrs:{"dismissible":"","show":"","variant":"success"},on:{"dismissed":_vm.uploadAlertDismissed}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(_vm._s(_vm.$t("HOME.welcome_alert.title")))]),_c('p',[_vm._v(_vm._s(_vm.$t("HOME.welcome_alert.line1")))]),_c('h4',{staticClass:"alert-heading"},[_vm._v(_vm._s(_vm.$t("HOME.upload_alert.title")))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("HOME.upload_alert.line1")))]),_c('b-btn',{staticClass:"mt-2",attrs:{"variant":"success","to":{ name: 'MatchNew' }}},[_vm._v(_vm._s(_vm.$t("HOME.upload_alert.submit")))])],1),(false)?_c('div',{staticClass:"col-md-4"},[(false)?_c('div',{staticClass:"latest-comments"},[_c('div',{staticClass:"section-title"},[_c('h5',[_vm._v(_vm._s(_vm.$t("UTILITY.latest_comments")))])]),_c('div',{staticClass:"comments-section"},_vm._l((_vm.comments),function(comment){return _c('div',{key:comment.id,staticClass:"comment-card"},[_c('div',{staticClass:"user-avatar"},[_c('router-link',{attrs:{"to":{
                    name: 'UserSingle',
                    params: { id: comment.user.id },
                  }}},[_c('img',{attrs:{"src":comment.user.avatar.url}})])],1),_c('div',{staticClass:"comment"},[_c('router-link',{attrs:{"to":{
                    name: 'RoundSingle',
                    params: { id: comment.commentable_id },
                  }}},[_c('div',{staticClass:"comment-body"},[_vm._v(_vm._s(comment.body))]),_c('div',{staticClass:"comment-footer"},[_vm._v(" "+_vm._s(_vm.$t("UTILITY.by"))+" "),_c('router-link',{attrs:{"to":{
                        name: 'UserSingle',
                        params: { id: comment.user.id },
                      }}},[_c('span',[_vm._v(_vm._s(comment.user.display_name))])]),_vm._v(" "+_vm._s(_vm.$t("UTILITY.at"))+" "+_vm._s(comment.created_at_friendly)+" ")],1)])],1)])}),0)]):_vm._e(),(false)?_c('div',{staticClass:"trending-rounds"},[_c('div',{staticClass:"section-title"},[_c('h5',[_vm._v(_vm._s(_vm.$t("UTILITY.trending_rounds")))])]),_c('div',{staticClass:"trending-section"},_vm._l((_vm.trendingRounds),function(round){return _c('MiniRound',{key:round.id,attrs:{"round":round}})}),1)]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-12 col-md-4"},[(_vm.user)?_c('div',[_c('div',{staticClass:"avatar"},[(_vm.user && _vm.user.avatar)?_c('b-img',{attrs:{"fluid":"","src":_vm.user.avatar.url}}):_vm._e()],1),_c('h1',[_vm._v(_vm._s(_vm.user.nickname))])]):_vm._e(),(_vm.user && _vm.user.latest_matches_stats)?_c('div',[_c('h4',[_vm._v("últimos jogos")]),_c('div',{staticClass:"latest-matches-widget"},_vm._l((_vm.user.latest_matches_stats),function(match){return _c('router-link',{key:match.match_id,class:("match " + (match.match.map)),attrs:{"to":{ name: 'MatchSingle', params: { id: match.match_id } }}},[_c('div',{staticClass:"kdr"},[_vm._v("kdr "+_vm._s(match.kdr))]),_c('div',{staticClass:"map"},[_vm._v(_vm._s(match.match.map))]),_c('div',{staticClass:"score"},[_vm._v(" "+_vm._s(match.match.team_a_score)+"/"+_vm._s(match.match.team_b_score)+" ")])])}),1)]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }