<template>
  <div class="container">
    <!-- <br> -->

    <div class="row mt-4">
      <div class="col-12 col-md-8">
        <b-alert
          dismissible
          @dismissed="uploadAlertDismissed"
          show
          variant="success"
        >
          <h4 class="alert-heading">{{ $t("HOME.welcome_alert.title") }}</h4>

          <p>{{ $t("HOME.welcome_alert.line1") }}</p>

          <h4 class="alert-heading">{{ $t("HOME.upload_alert.title") }}</h4>
          <p class="mb-0">{{ $t("HOME.upload_alert.line1") }}</p>

          <b-btn variant="success" class="mt-2" :to="{ name: 'MatchNew' }">{{
            $t("HOME.upload_alert.submit")
          }}</b-btn>
        </b-alert>

        <div class="col-md-4" v-if="false">
          <div class="latest-comments" v-if="false">
            <div class="section-title">
              <h5>{{ $t("UTILITY.latest_comments") }}</h5>
            </div>

            <div class="comments-section">
              <div
                v-for="comment in comments"
                :key="comment.id"
                class="comment-card"
              >
                <div class="user-avatar">
                  <router-link
                    :to="{
                      name: 'UserSingle',
                      params: { id: comment.user.id },
                    }"
                  >
                    <img :src="comment.user.avatar.url" />
                  </router-link>
                </div>
                <div class="comment">
                  <!-- <div class="user-details">

                  </div>-->
                  <router-link
                    :to="{
                      name: 'RoundSingle',
                      params: { id: comment.commentable_id },
                    }"
                  >
                    <div class="comment-body">{{ comment.body }}</div>
                    <div class="comment-footer">
                      {{ $t("UTILITY.by") }}
                      <router-link
                        :to="{
                          name: 'UserSingle',
                          params: { id: comment.user.id },
                        }"
                      >
                        <span>{{ comment.user.display_name }}</span>
                      </router-link>
                      {{ $t("UTILITY.at") }} {{ comment.created_at_friendly }}
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="trending-rounds" v-if="false">
            <div class="section-title">
              <h5>{{ $t("UTILITY.trending_rounds") }}</h5>
            </div>
            <div class="trending-section">
              <MiniRound
                :round="round"
                v-for="round in trendingRounds"
                :key="round.id"
              ></MiniRound>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div v-if="user">
          <div class="avatar">
            <b-img fluid :src="user.avatar.url" v-if="user && user.avatar" />
          </div>
          <h1>{{ user.nickname }}</h1>
        </div>

        <div v-if="user && user.latest_matches_stats">
          <h4>últimos jogos</h4>
          <div class="latest-matches-widget">
            <router-link
              :to="{ name: 'MatchSingle', params: { id: match.match_id } }"
              v-for="match in user.latest_matches_stats"
              :key="match.match_id"
              :class="`match ${match.match.map}`"
            >
              <div class="kdr">kdr {{ match.kdr }}</div>
              <div class="map">{{ match.match.map }}</div>
              <div class="score">
                {{ match.match.team_a_score }}/{{ match.match.team_b_score }}
              </div>
            </router-link>
          </div>
        </div>

        <!-- <h1>indicadores</h1> -->
      </div>
    </div>
  </div>
</template>

<script>
import MiniRound from "@/components/MiniRound";

export default {
  name: "Home",
  data() {
    return {
      matches: [],
      trendingRounds: [],
      comments: [],
      user: {},
      page: 1,
      perPage: 0,
      totalRecords: 0,

      // chartOptions: {},
      // series: {}
    };
  },
  methods: {
    initialize() {
      this.$router.push("/my_matches");
      this.loadComments();
      this.loadTrending();
      this.loadUser();
    },
    loadComments() {
      this.$http
        .get("latest_comments.json")
        .then((response) => (this.comments = response.data));
    },
    loadTrending() {
      this.$http
        .get("trending_rounds.json")
        .then((response) => (this.trendingRounds = response.data));
    },
    loadUser() {
      var self = this;
      this.$http.get("users/current_user.json").then((response) => {
        self.user = response.data;
        //   labels: response.data.latest_matches_stats.map(el => el.match_id),
      });
    },
    welcomeAlertDismissed() {
      console.log("fechei welcome");
    },
    uploadAlertDismissed() {
      console.log("fechei upload");
    },
  },
  mounted() {
    this.initialize();
  },
  components: {
    MiniRound,
  },
};
// Header
</script>

<style lang="sass" scoped>
a:hover
  text-decoration: none
  color: #333
.match-single
  p
    margin: 0
.team-column
  display: flex
  // justify-content: space-around
  div
    font-size: 18px
    font-weight: bold
    &:first-child
      margin-right: 5px
    &:last-child
      margin-left: 5px

.latest-comments
  .comments-section
    // border-top: 1px solid #ccc
    // margin-top: 20px
    // padding-top: 20px
    .comment-card
      display: flex
      align-items: center
      margin: 10px 0
      .user-avatar
        width: 48px
        height: 48px
        min-width: 48px
        display: block
        overflow: hidden
        // padding: 5px
        margin: 5px
        margin-right: 15px
        img
          width: 100%
          height: auto
          border-radius: 250%
          // min-width: 48px
      a
        color: #666
        &:hover
          color: #222
      .comment
        background: #f1f1f1
        flex-grow: 1
        padding: 4px 8px
        border-radius: 4px
        position: relative

        &:before
          content: ''
          height: 0
          width: 0
          position: absolute
          display: block
          border-width: 9px 7px 9px 0
          border-style: solid
          border-color: transparent #f1f1f1
          top: 8px
          left: -6px
          // background: #f1f1f1

        .comment-body
          line-height: 125%
        .user-details
          span
            font-weight: bold
        .comment-footer
          font-size: 12px
          a
            font-weight: bold
    .new-comment
      margin-top: 30px
.trending-rounds
  margin-top: 20px

.latest-matches-widget
  display: flex
  justify-content: space-around
  align-items: center
  flex-wrap: wrap

  .match
    width: 24%
    color: white
    display: flex
    justify-content: center
    flex-wrap: wrap
    border-radius: 5px
    padding: 5px 0

    .kdr
      width: 100%
      text-align: center
    .map
      width: 100%
      text-align: center
      font-size: 11px
    .score
      width: 100%
      text-align: center
</style>
